.modal {
  text-align: center;
  z-index: 2500 !important;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-backdrop.show {
  z-index: 2500;
  height: 100%;
}

.modal-content {
  border-radius: 0px !important;
  padding: 24px;
}

.modal_popup {
  min-width: 640px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.45);
}

.cross {
  display: flex;
  flex-direction: row-reverse;
}

.cross_img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.model_heading {
  height: 35px;
  color: #000000;
  font-size: 27.65px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 34.56px;
  text-align: center;
  margin-top: 24px;
}

.hr_top {
  border: 1px solid #cccccc;
  margin: 11.5px 0 11.5px 0;
}

.hr_bottom {
  border: 1px solid #cccccc;
  margin: 23.5px 0 23.5px 0;
}

.instruction {
  height: 20px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.field_label {
  height: 17px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.label1 {
  margin: 12px 0 5px 0;
}

.label2 {
  margin: 16px 0 4px 0;
}

.field_ddl {
  height: 40px;
  line-height: 18px;
  width: 100%;
}

.textarea_field {
  box-sizing: border-box;
  height: 124px;
  border: 1px solid #424242;
  background-color: #ffffff;
  width: 100%;
}

.button_container {
  text-align: center;
}

.retireLeadButton {
  height: 40px;
  width: 180px;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 17px !important;
  text-align: center;
}

.save_lead {
  background-color: #ffb91d !important;
  margin-left: 16px !important;
}

.cancel_lead {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
  margin: 0px !important;
}

.cancel_lead:hover {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}

.cancel_lead:focus {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}

@media only screen and (max-width: 375px) {
  .modal-content {
    padding: 12px;
  }

  .modal_popup {
    min-width: 320px;
  }

  .cross_img {
    width: 20px;
    height: 20px;
  }

  .model_heading {
    height: 24px;
    font-size: 19.2px;
    margin-top: 12px;
    line-height: 24px;
  }

  .hr_top {
    margin: 14.5px 0 11.5px 0;
  }

  .retireLeadButton {
    height: 40px;
    width: 142px;
    min-width: 142px !important;
  }

  .save_lead {
    margin-left: 12px !important;
  }
}
