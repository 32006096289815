.notesWrapper {
  /* border: 1px solid #d2d2d2;
  padding: 15px;
  border-radius: 3px; */
  padding-top: 12px;
}
.note-card-wrapper {
  min-height: 250px;
  font-size: 13px;
  max-height: 350px;
  overflow: auto;
}
.note-card {
  border: 1px solid #d2d2d2;
  margin: 15px;
  margin-bottom: 0 !important;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}
.note-card-middle {
  border-top: 0px !important;
  border: 1px solid #d2d2d2;
  margin: 0 15px;
  padding: 10px;
}
.pad-0 {
  padding: 0 !important;
}
.note-select {
  min-height: 36px !important;
  border-radius: 0px !important;
}

.note-label {
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}
.head-button-wrapper {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
}

.lead_title {
  margin-bottom: 8px;
  display: inline-block;
}
.btn-gray {
  border: 2px solid #cccccc !important;
  color: #999999 !important;
  background-color: #ffffff;
}
.btn-gray:hover {
  color: #424242 !important;
  background-color: #ffffff;
}

.create_note_btn {
  float: right;
}

@media only screen and (max-width: 576px) {
  .notesWrapper .head-button-wrapper {
    top: 2px;
    position: inherit !important;
  }
}

@media only screen and (max-width: 375px) {
  .create_note_btn {
    width: 100%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
