.openLeadsWrapper {
  border: 1px solid #d2d2d2;
  padding: 15px;
  border-radius: 3px;
}
.anchor-text {
  color: #0076cc !important;
}
.anchor-text:hover {
  text-decoration: underline;
}
.customIcon {
  width: 35px;
  margin-left: -5px;
  height: 37px;
  margin-top: -6px;
  padding-top: 10px;
}
@media only screen and (max-width: 375px) {
  /* .tab-content {
    margin-top: 24px;
  } */
}
