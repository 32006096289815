/*.oecWrapper {
  background-color: #f2f2f2;
  padding: 30px;  Closing based on the Layout
}*/

.salesLeadWrapper {
  background-color: #ffff !important;
  margin: 0 !important;
  max-width: 100% !important;
  padding: 0px 72px 0px 72px !important;
}

.salesLeadHeading {
  display: grid;
  margin-bottom: 30px;
}

.no-margin {
  margin: 0;
}

.headingRight {
  margin-left: auto;
  order: 2;
}

.tab-heads {
  border-bottom: 2px solid #b3b3b3 !important;
  /* height: 61px; */
  margin-bottom: 16px !important;
}

.tab-heads .nav-item {
  margin-bottom: -2px !important;
}

.tab-heads .nav-item button {
  margin: 0 0 0 25px;
  border: 2px solid #b3b3b3;
  border-radius: 0;
  color: #000 !important;
  background-color: #fff;
  padding: 14px 10px;
  font-size: 12px;
  max-width: 175px;
  font-weight: bold;
  border-bottom: unset;

  /* width: 226px;
  margin-left: 32px;
  border: 2px solid #b3b3b3;
  border-radius: 0;
  color: #000000 !important;
  font-size: 19.2px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  height: 100%;
  padding: 4px; */
}

.tab-heads .nav-item .active {
  border-top: 4px solid #ffb91d !important;
  border-left: 2px solid #b3b3b3 !important;
  border-right: 2px solid #b3b3b3 !important;
}

.transparent-button {
  line-height: 18px !important;
  height: 40px;
  /* min-width: 210px !important; */
  background-color: white !important;
  border: 2px solid #ffb91d !important;
  color: #000 !important;
  font-size: 16px !important;
  cursor: pointer !important;
  margin: 0 0 0 24px !important;
  border-radius: 26.5px !important;
  padding: 0.5rem !important;
}

.transparent-button:hover,
.transparent-button:active,
.transparent-button:focus {
  background: #ffb91d !important;
  border: 2px solid #ffb91d !important;
  cursor: pointer !important;
}

.parent_btn {
  display: flex;
}

.child_btn {
  display: flex;
}

.action_container {
  float: right;
}

.action_btn {
  text-align: left !important;
}

.action_btn_arrow {
  float: right;
}

.action_box {
  height: 81px;
  width: 170px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: 12px;
  z-index: 1;
  margin-left: 8px;
}

.btn_cancel {
  background: #000 !important;
  color: #fff !important;
  border: 2px solid #000 !important;
}
.btn_cancel:hover {
  color: #424242 !important;
}
.btn_act {
  background: #ffb91d !important;
}
.btn_act:hover {
  background-color: #e9a100 !important;
  color: #424242 !important;
}

.action_box_row {
  height: 30px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  cursor: pointer;
  padding-left: 8px;
}

.action_box_row:hover {
  background-color: #e6e6e6;
}

.actionCrossRow {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 8px 0 0;
}

.crossIcon {
  cursor: pointer;
}

.modal {
  z-index: 999999 !important;
}
@media only screen and (max-width: 1440px) {
  .salesLeadWrapper {
    padding: 0px 32px 0px 32px !important;
  }
}

@media only screen and (max-width: 1024px) {
  /* .tab-heads .nav-item button {
    width: 216px;
    margin-left: 16px;
    font-size: 18.2px;
  } */
}

@media only screen and (max-width: 768px) {
  .transparent-button {
    min-width: 168px !important;
    margin: 0 0 0 16px !important;
  }

  .btn_cancel {
    margin-left: 0px !important;
  }

  .salesLeadWrapper {
    padding: 0px 24px 0px 24px !important;
  }

  /* .tab-heads {
    border-bottom: 2px solid #b3b3b3 !important;
    height: 51px;
    margin-bottom: 23.5px !important;
  } */

  /* .tab-heads .nav-item button {
    width: 168px;
    margin-left: 10px;
    font-size: 14.2px;
  } */

  .btn_cancel_parent {
    float: right;
  }
}

@media only screen and (max-width: 690px) {
  .tab-heads .nav-item button {
    margin: 0px 2px 0px 0px;
  }
  .headingRight .action_container {
    float: left;
  }
  /* .headingRight button {
    margin-bottom: 4px !important;
    text-align: center !important;
  } */
  .summ .detailContainer {
    margin-left: 0px;
    margin-bottom: 2px;
  }
  .summ .sideNav {
    min-height: 15px;
  }
}

@media only screen and (max-width: 375px) {
  /* .tab-heads .nav-item button {
    margin: 0px 2px 0px 0px;
  } */
  .headingRight .action_container {
    float: left;
  }
  /* .headingRight button {
    margin-bottom: 4px !important;
  } */
  .summ .detailContainer {
    margin-left: 0px;
    margin-bottom: 2px;
  }
  .summ .sideNav {
    min-height: 15px;
  }

  .salesLeadWrapper {
    padding: 0px 16px 0px 16px !important;
  }

  .set_btn_spnce {
    margin-left: 11px !important;
    margin-bottom: 0px !important;
  }

  .unset_btn_spnce {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }

  .btn_cancel {
    margin-bottom: 0px !important;
  }

  .btn_row1 {
    padding-bottom: 12px;
  }

  .parent_btn {
    display: flex;
    flex-direction: column;
  }

  .tab-heads {
    display: contents !important;
  }

  .tab-heads .nav-item button {
    width: 100%;
    max-width: 100%;
    /* font-size: 14.2px; */
    margin-bottom: 8px;
    border-bottom: 2px solid #b3b3b3;
  }

  .btn_cancel_parent {
    float: unset;
  }

  .btn_cancel_only {
    width: 100%;
  }
}
