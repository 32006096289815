.css-bkt10z-control {
  background-color: unset !important;
}

.summ {
  margin: 15px;
}
.sideNav {
  border: solid #ccc 1px;
  padding: 12px;
  /* min-height: 250px; */
}

.detailCol {
  padding: 0 !important;
}

.detailContainer {
  border: solid #ccc 1px;
  padding: 0px 12px 12px 12px;
  margin-left: 10px;
  margin-top: 0;
}

.summaryTab {
  width: 100%;
}

.noteTab {
  width: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  border: solid #000 1.5px;
  background-color: #fff;
  border-radius: 0px;
}
.form-control {
  color: #212529;
  /*border: 1px solid #505152;
   height: 30px; as this was conflicting with Header Footer. Assign a parent class to avoid the issue*/
  border-radius: 0px;
}
.form-select {
  color: #212529;
  border: 1px solid #505152;
  border-radius: 0px;
  height: 30px;
  padding: 0.175rem 2.25rem 0.155rem 0.75rem;
}
.owner {
  width: 45%;
}
.btnRjct {
  box-sizing: border-box;
  height: 35px;
  width: 212px;
  border: 2px solid #ffb91d;
  border-radius: 26.5px;
  background-color: #ffffff !important;
  color: #000;
  float: right;
  margin-top: -2rem;
  padding: 0rem;
}
.btnRjct:hover {
  color: #000;
  border: 1px solid #ffb91d;
  background-color: #ffb91d !important;
}
.statusgrp {
  height: 40px;
}

.fw-400 {
  box-sizing: border-box;
  height: 40px;
  width: 589px;
  border: 1px solid #000000;
  background-color: #ffffff;
}
select {
  height: 21px;
  width: 135px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
}
.sbox {
  height: 48px;
  width: 48px;
  background-color: #d8d8d8;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-sizing: border-box;
  height: 45px;
  /* width: 280px; */
  border: 1.5px solid #000000;
  background-color: #ffffff !important;
  color: #000 !important;
}

.btn-status-unqualified {
  border-radius: 20px 0 0 20px;
}

.btn-status-qualified {
  border-radius: 0 20px 20px 0;
}

.btn-status {
  box-sizing: border-box;
  border: 1px solid #ffb91d !important;
  background-color: #ffffff;
  height: 25px;
  width: 80px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 0px;
  text-align: center;
}
.btn-status-toggle {
  background-color: #ffb91d;
}

.sl-Label {
  height: 17px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
}
.fw-400 {
  box-sizing: border-box;
  height: 40px;
  width: 589px;
  border: 1px solid #000000;
  background-color: #ffffff;
  font-size: 16px;
}
.fw-400 .rd {
  background-color: #d8d8d8 !important;
}
.fw-400.tx {
  height: auto;
}
select {
  height: 21px;
  width: 135px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
}
select {
  height: 21px;
  width: 135px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
}
.sbox {
  height: 48px;
  width: 48px;
  background-color: #d8d8d8;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-sizing: border-box;
  height: 45px;
  border: 1.5px solid #000000;
  background-color: #ffffff !important;
  color: #000 !important;
}
.nav-pills .nav-link {
  color: black;
}
button:hover {
  border: 1px solid #e9a100;
}

.custom-select-box input {
  box-shadow: none !important;
}

.product-details-table .rdt_Table {
  width: 1000px !important;
}

.product-details-title {
  font-size: 23.4px;
  color: #000000;
  line-height: 29.25px;
  margin-top: 48px !important;
  margin-bottom: 12px;
}

div.product-details-table::-webkit-scrollbar {
  height: 10px;
}
div.product-details-table::-webkit-scrollbar-track {
  background: #d8d8d8;
}

div.product-details-table::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 5px;
}

div.product-details-table::-webkit-scrollbar-thumb:hover {
  background: #9e9c9c;
}
.product-details-table .rdt_TableCol {
  font-size: 13.3px;
  font-weight: bold;
  line-height: 16.63px;
  padding: 2px 5px;
}
.product-details-table .rdt_TableCell .fw-400 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13.3px;
  line-height: 16.63px;
  font-weight: unset;
  padding: 5px;
}
.product-details-table .rdt_TableCell textarea.fw-400 {
  white-space: unset;
}

@media only screen and (min-width: 1920px) {
  .product-details-table .rdt_TableCol,
  .product-details-table .rdt_TableCell .fw-400 {
    font-size: 16px;
    line-height: 19px;
  }
  .product-details-title {
    font-size: 27.65px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1440px) {
  .product-details-table > div {
    display: block !important;
  }
  .product-details-table .rdt_Table {
    width: unset !important;
  }
}

@media only screen and (max-width: 900px) {
  .salesLeadHeading .headingRight {
    margin-left: 0;
  }
  .statusgrp button.btn-status {
    min-width: 105px;
  }
}

@media only screen and (max-width: 768px) {
  .statusgrp button.btn-status {
    min-width: 105px;
  }

  .detailContainer {
    margin-left: 0;
    margin-top: 10px;
  }

  .summaryTab {
    width: 50%;
  }

  .noteTab {
    width: 50%;
  }
}

@media only screen and (max-width: 375px) {
  /* .detailContainer {
    margin-left: 0;
    margin-top: 10px;
  } */

  .summaryTab {
    width: 100%;
  }

  .noteTab {
    width: 100%;
  }
}
/* Radio button css */
.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #ffffff;
  border: 1px solid #ffb91d !important;
  color: rgba(0, 0, 0);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
  width: 160px;
  border-radius: 0 20px 20px 0;
}

.switch-field label:hover {
  cursor: pointer;
  background-color: #e9a100;
}

.switch-field input:checked + label {
  background-color: #ffb91d;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 20px 0 0 20px;
}
/* Radio button css end */

.ptext {
  padding: 0.625rem 1.5625rem 0.625rem 0.625rem;
}
.CamelC{
  text-transform: capitalize;
}
