.rdw-editor-toolbar {
  border: 1px solid !important;
}

.rdw-editor-main {
  border: 1px solid !important;
}

.DraftEditor-root {
  height: 250px !important;
  z-index: 0;
  margin: 5px;
}

.lead_name {
  font-size: 19.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
}

.note-card-wrapper {
  font-size: 13px;
}
.note-card {
  border: 1px solid #d2d2d2;
  margin: 15px;
  margin-bottom: 0 !important;
  padding: 10px;
}
.note-card-middle {
  border-top: 0px !important;
  border: 1px solid #d2d2d2;
  margin: 0 15px;
  padding: 10px;
}
.pad-0 {
  padding: 0 !important;
}
.note-select {
  min-height: 36px !important;
  border-radius: 0px !important;
}

.note-label {
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}
.head-button-wrapper {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
}
.ta {
  box-sizing: border-box;
  height: 272px;
  width: 1202px;
  border: 1px solid #ccd2d7;
  background-color: #e6e6e6;
}
.btn-edit {
  box-sizing: border-box;
  height: 42px;
  width: 212px;
  border: 2px solid #cccccc !important;
  border-radius: 26.5px;
  background-color: #ffffff !important;
  color: #cccccc !important;
}
.btn-edit:hover {
  color: #000 !important;
}

.note_btn_container {
  text-align: end;
  padding-top: 24px;
}

.note_button {
  height: 40px;
  width: 210px;
  color: #000000;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center;
  margin-left: 24px !important;
}

.cancel_note {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
  margin: 0px !important;
}

.cancel_note:hover {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}

.cancel_note:focus {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}

@media only screen and (max-width: 1024px) {
  .note_button {
    width: 184px;
    margin-left: 12px !important;
  }

  .cancel_note {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .note_button {
    width: 100%;
    margin: 12px 4px 0 4px !important;
  }

  .note_btn_container {
    display: flex;
    /* flex-direction: column-reverse; */
    padding-top: unset;
  }
}

@media only screen and (max-width: 375px) {
  .note_button {
    height: 40px;
    width: 100%;
    color: #000000;
    font-size: 16px !important;
    letter-spacing: 0;
    line-height: 20px !important;
    text-align: center;
    margin: 12px 0 0 0 !important;
  }

  .note_btn_container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: unset;
  }
}
.warning_msg {
  color: #0076cc;
  font-size: 20px;
}
